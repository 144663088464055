import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { ArrowBack, Delete, Refresh } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import * as api from 'app/api';
import ConfirmationDialog from 'app/components/ConfirmationDialog';
import DiplomasPageConfirmation from 'app/components/DiplomasPageConfirmation';
import DiplomasTaskDialog from 'app/components/DiplomasTaskDialog';
import Header from 'app/components/Header';
import Main from 'app/components/Main';
import RefreshableSubheader from 'app/components/RefreshableSubheader';
import { DEBOUNCE_DEFAULT } from 'app/constants/debounce';
import { Routes } from 'app/router/Routes';
import { AppDispatch, RootState } from 'app/store';
import { accessTokenSelector } from 'app/store/auth';
import diplomasSlice, { fetchTemplateDiplomasThunk } from 'app/store/diplomas';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { compile } from 'path-to-regexp';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useUnmount } from 'react-use';

type Props = RouteComponentProps<{ templateId: string }>;

const PER_PAGE = 15;

const TALENT_SEARCH_MODE = 'talent_id';
const ACHIEVEMENT_SEARCH_MODE = 'achievement';

export default function TemplateDiplomasPage({ match, history }: Props) {
  const templateId = match.params.templateId;
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(accessTokenSelector)!;
  const { data } = useSelector((state: RootState) => state.diplomas);
  const queryParams = useMemo(() => qs.parse(history.location.search), [
    history.location.search,
  ]);
  const page = Number(queryParams['page']) || 1;
  const [showAchInput, setShowAchInput] = useState(false);
  const [achievementsIds, setAchievementsIds] = useState<String>('');
  const [showAlreadyCreatingModal, setShowAlreadyCreatingModal] = useState(
    false
  );
  const search = queryParams['search'] ? String(queryParams['search']) : '';
  const searchMode = queryParams['searchMode']
    ? String(queryParams['searchMode'])
    : TALENT_SEARCH_MODE;
  const pages = useMemo(() => Math.ceil((data?.length || 0) / PER_PAGE), [
    data,
  ]);
  const [isDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<api.types.Diploma['id']>();
  const [refreshData, setRefreshData] = useState<api.types.IRefreshData | null>(
    null
  );
  const [
    deleteAndCreateData,
    setDeleteAndCreateData,
  ] = useState<api.types.IDeleteAndCreateData | null>(null);
  const [task, setTask] = useState<api.types.DiplomasTask>();
  const [groupTask, setGroupTask] = useState<api.types.DiplomasTask>();
  const [lastTaskId, setLastTaskId] = useState('');
  const params = useMemo<api.types.DiplomasByTempateIdParams>(() => {
    if (search) {
      switch (searchMode) {
        case TALENT_SEARCH_MODE:
          return {
            user_id: search,
            success: true,
          };
        case ACHIEVEMENT_SEARCH_MODE:
          return {
            achievement_id: search,
            success: true,
          };
      }
    }
    return { success: true };
  }, [search, searchMode]);
  useEffect(() => {
    dispatch(
      fetchTemplateDiplomasThunk({
        access_token: accessToken,
        templateId,
        params,
      })
    );
  }, [accessToken, dispatch, params, templateId]);
  useEffect(() => {
    api
      .getDiplomasTaskList(accessToken, {
        template_id: templateId,
        ordering: '-created_at',
        limit: 1,
      })
      .then(({ data }) => {
        if (data?.length) {
          setLastTaskId(data[0]?.id);
        }
      });
  }, [accessToken, templateId]);
  useEffect(() => {
    if (groupTask?.id) {
      history.push({
        pathname: `/templates/${templateId}/create_diplomas/${groupTask.id}`,
      });
    }
  }, [groupTask, history, templateId]);
  const handleSearch = useMemo(
    () =>
      debounce(
        (search: string) =>
          history.push({
            ...history.location,
            search: qs.stringify({ search, searchMode }),
          }),
        DEBOUNCE_DEFAULT
      ),
    [history, searchMode]
  );

  const handleInputAchievements = debounce(
    (search: string) => setAchievementsIds(search),

    DEBOUNCE_DEFAULT
  );
  const getIsDiplomasCreating = async () => {
    try {
      if (!lastTaskId) return false;
      const { data: progress } = await api.getDiplomasProgress(
        accessToken,
        lastTaskId
      );
      if (progress.Total && progress.Total > progress.Current) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };
  const addDiploma = () => {
    if (!achievementsIds || !achievementsIds.trim()) {
      return;
    }
    const achForCreate: number[] = achievementsIds
      .replace(/\s+/g, '')
      .split(',')
      .map(Number)
      .filter((item) => !isNaN(item));

    const idsToDelete =
      data
        ?.filter((diploma) => achForCreate.includes(diploma.achievement_id))
        .map((diploma) => diploma.id) || [];

    if (idsToDelete.length) {
      setDeleteAndCreateData({
        idsToDelete,
        achForCreate,
      });
      return;
    }

    api
      .createDiplomasTask(accessToken, {
        template_id: match.params.templateId,
        only_achievements: achForCreate,
      })
      .then((res) => {
        setGroupTask(res.data);
      });
  };
  const indexById = useMemo(() => {
    const ret: Record<string, number> = {};
    [...(data || [])].reverse().forEach((diploma, index) => {
      ret[diploma.id] = index + 1;
    });
    return ret;
  }, [data]);

  useUnmount(() => {
    dispatch(diplomasSlice.actions.reset());
  });
  return (
    <>
      <Header />
      <Main>
        <Box display="flex" justifyContent="space-between">
          <Button
            component={Link}
            to={compile(Routes.TEMPLATE)({ id: templateId })}
            variant="outlined"
            startIcon={<ArrowBack />}
          >
            Шаблон
          </Button>
          {lastTaskId ? (
            <Button
              component={Link}
              to={compile(Routes.CREATE_DIPLOMAS)({
                templateId,
                id: lastTaskId,
              })}
              variant="outlined"
            >
              Последняя задача
            </Button>
          ) : null}
        </Box>
        <Box my={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => setDelete(true)}
            disabled={!data?.length}
            color="secondary"
            size="small"
          >
            Удалить все
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            style={{ flex: '1 1 80%' }}
            variant="outlined"
            placeholder="Поиск"
            defaultValue={search}
            onChange={(event) => handleSearch(event.target.value)}
          />
          <Select
            style={{ flex: '1 1 20%' }}
            variant="outlined"
            defaultValue={searchMode}
            onChange={(event) =>
              history.push({
                ...history.location,
                search: qs.stringify({
                  search,
                  searchMode: event.target.value as any,
                }),
              })
            }
          >
            <MenuItem value={TALENT_SEARCH_MODE}>Talent ID</MenuItem>
            <MenuItem value={ACHIEVEMENT_SEARCH_MODE}>Достижение</MenuItem>
          </Select>
        </Box>

        <Box my={2} display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            color="primary"
            onClick={() => setShowAchInput(!showAchInput)}
          >
            {showAchInput ? 'Скрыть панель' : 'Создать дипломы'}
          </Button>
        </Box>

        {showAchInput ? (
          <Box display="flex">
            <div style={{ flex: '1 1 80%' }}>
              <TextField
                style={{ width: '100%' }}
                variant="outlined"
                placeholder="Введите id достижений"
                defaultValue={achievementsIds}
                onChange={(event) =>
                  handleInputAchievements(event.target.value)
                }
              />
              <FormHelperText>
                Введите id&nbsp;достижений через запятую. Например: 111,222
              </FormHelperText>
            </div>
            <Button
              style={{
                flex: '1 1 20%',
                height: '56px',
                minWidth: '110px',
              }}
              size="large"
              variant="contained"
              color="primary"
              onClick={async () => {
                const isCreating = await getIsDiplomasCreating();
                if (isCreating) {
                  setShowAlreadyCreatingModal(true);
                  return;
                }
                addDiploma();
              }}
            >
              Создать
            </Button>
            <br />
          </Box>
        ) : null}

        <List
          subheader={
            <RefreshableSubheader title="Дипломы" count={data?.length} />
          }
        >
          {data
            ?.slice((page - 1) * PER_PAGE, page * PER_PAGE)
            .map((diploma) => (
              <ListItem
                component="a"
                href={diploma.pdf}
                button
                key={diploma.id}
              >
                <ListItemText
                  primary={`${indexById[diploma.id]}) ${diploma.id}`}
                  secondary={
                    <Box>
                      <Typography>
                        Talent ID: {diploma.user_id || 'не указано'}
                      </Typography>
                      <Typography>
                        Team ID: {diploma.team_id || 'не указано'}
                      </Typography>
                      <Typography>
                        Достижение: {diploma.achievement_id}
                      </Typography>
                      <Typography>Task: {diploma.task_id}</Typography>
                      <Typography>
                        Дата:{' '}
                        {moment(diploma.created_at).format(
                          'DD.MM.YYYY HH:mm:ss'
                        )}
                      </Typography>
                    </Box>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() =>
                      setRefreshData({
                        template_id: diploma.template_id,
                        only_achievements: [diploma.achievement_id],
                        diplomaId: diploma.id,
                      })
                    }
                  >
                    <Refresh />
                  </IconButton>
                  <IconButton onClick={() => setDeleteId(diploma.id)}>
                    <Delete color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        <DiplomasPageConfirmation
          setDelete={setDelete}
          templateId={templateId}
          isDelete={isDelete}
          setDeleteId={setDeleteId}
          deleteId={deleteId}
          deleteAndCreateData={deleteAndCreateData}
          setDeleteAndCreateData={setDeleteAndCreateData}
          setGroupTask={setGroupTask}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
          setTask={setTask}
        />
        {pages > 1 && (
          <Pagination
            count={pages}
            page={page}
            onChange={(_, page) =>
              history.push({
                ...history.location,
                search: qs.stringify({ ...queryParams, page }),
              })
            }
          />
        )}

        {task && (
          <DiplomasTaskDialog
            open
            task={task}
            onPoll={() => {
              api.getDiplomasTask(accessToken, task.id).then((res) => {
                setTask(res.data);
              });
            }}
            onFinish={() =>
              dispatch(
                fetchTemplateDiplomasThunk({
                  access_token: accessToken,
                  templateId,
                  params,
                })
              )
            }
            onClose={() => setTask(undefined)}
          />
        )}
      </Main>
      <ConfirmationDialog
        onClose={() => {
          setShowAlreadyCreatingModal(false);
        }}
        disableBackdropClick
        disableEscapeKeyDown
        title="Уже имеется запущенная задача"
        text="Сейчас выполняется процесс создания дипломов. Вы можете подождать его завершения или запустить новую задачу, если уверены в необходимости, но это может привести к параллельному выполнению процессов."
        submitText="Создать новую задачу"
        cancelText="Отменить"
        open={showAlreadyCreatingModal}
        onSubmit={() => {
          setShowAlreadyCreatingModal(false);
          addDiploma();
        }}
      />
    </>
  );
}
